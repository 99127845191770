import {
  Button,
  Container,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import {
  addDoc,
  collection,
  getDocs,
  getFirestore,
  query,
  where,
} from "firebase/firestore";
import { app } from "../../utils/server/firebase";
import React, { useState } from "react";
import Footer from "../../assets/components/Footer";
import Navbar from "../../assets/components/Navbar";
import Seo from "../../assets/components/seo";
import { sendEmail, validateEmail } from "../../utils/functions";
import { toast } from "react-toastify";
import { ESTADOS } from "../../utils/constants";

const db = getFirestore(app);

const DuelodFogonesInternacional = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const conversatorios = [
    "Potencial Gastronómico de la Cocina Tradicional",
    "¿Cómo cocinaste tu éxito?",
    "Secretos ancestrales de tu cocina",
  ];

  const handleSubmit = async () => {
    try {
      setLoading(true);

      if (
        !data?.nombre?.trim() ||
        !data?.cocina?.trim() ||
        !data?.edad?.trim() ||
        !data?.genero?.trim() ||
        !data?.pais?.trim() ||
        !data?.telefono?.trim() ||
        !data?.email?.trim() ||
        !data?.platillo?.trim()
      ) {
        return toast.warning(
          "Favor de llenar todos los campos obligatorios (*)"
        );
      } else if (!validateEmail(data?.email?.trim())) {
        return toast.info("Revise que el correo electrónico sea valido");
      }
      try {
        const q = query(
          collection(db, "encuentroCocineras"),
          where("email", "==", data.email)
        );
        const querySnapshot = await getDocs(q);

        if (querySnapshot.docs.length > 0)
          return toast.error(
            "El email que ingresó ya se encuentra registrado para este concurso"
          );
      } catch (error) {
        return toast.warning("Ocurrio un error: " + error.message);
      }

      const docsRef = collection(db, "encuentroCocineras");
      await addDoc(docsRef, {
        nombre: data?.nombre,
        telefono: data?.telefono,
        email: data?.email,
        edad: data?.edad || "",
        genero: data?.genero || "",
        estado: data?.estado || "",
        pais: data?.pais,
        web: data?.web || "",
        cocina: data?.cocina,
        facebook: data?.facebook || "",
        instagram: data?.instagram || "",
        tiktok: data?.tiktok || "",
        experiencia: data?.experiencia || "",
        platillo: data?.platillo,
        emprendimiento: data?.emprendimiento || "",
        charla: data?.charla || "",
        participarChocolateMesaAgua: data?.participarChocolateMesaAgua || "",
        tallerCocina: data?.tallerCocina || "",
        cataChocolate: data?.cataChocolate || "",
        conversatorio: data?.conversatorio || "",
        createAt: new Date(),
      });
      toast.success("Registro finalizado");
    } catch (error) {
      console.log(error);
      toast.error(
        "Hubo un problema al crear tu solicitud. Recargue e intente de nuevo."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container
      maxWidth={false}
      sx={{ padding: "0 !important", margin: "0 !important" }}
    >
      <Seo />
      <Navbar />

      <Grid container justifyContent="center" marginY={12}>
        <Grid container maxWidth="md" spacing={2} paddingX={4}>
          <Grid item container alignItems="center">
            <Grid item xs={12}>
              <Typography variant="h5">
                1er Encuentro de cocineras y duelo de fogones
              </Typography>
            </Grid>
            <Grid item>
              <Typography>
                Concurso Gastronómico de impacto INTERNACIONAL, que forma parte
                del programa “Sabor a Tabasco”, y que tiene como objetivo el
                intercambio de sabores y saberes gastronómicos vinculados a la
                cocina tradicional, así como la puesta en valor y difusión de la
                identidad culinaria de las diferentes regiones de Mesoamérica.
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              variant="standard"
              label="Nombre"
              fullWidth
              required
              value={data?.nombre || ""}
              onChange={(e) => {
                setData({ ...data, nombre: e.target.value });
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              variant="standard"
              label="¿En qué tipo de cocina se especializa?"
              fullWidth
              required
              value={data?.cocina || ""}
              onChange={(e) => {
                setData({ ...data, cocina: e.target.value });
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              variant="standard"
              label="Edad"
              fullWidth
              value={data?.edad || ""}
              onChange={(e) => {
                const regex = /^[0-9\b]+$/;
                if (e.target.value == "" || regex.test(e.target.value)) {
                  setData({ ...data, edad: e.target.value });
                }
              }}
              inputProps={{ maxLength: 3 }}
            />
          </Grid>
          <Grid item container xs={12} md={6}>
            <Grid item xs={12}>
              <Typography variant="body1" color="GrayText">
                Genero
              </Typography>
            </Grid>
            <RadioGroup
              onChange={(e) => {
                setData({ ...data, genero: e.target.value });
              }}
              row
            >
              <FormControlLabel
                value="Masculino"
                control={<Radio />}
                label="Masculino"
              />
              <FormControlLabel
                value="Femenino"
                control={<Radio />}
                label="Femenino"
              />
            </RadioGroup>
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              variant="standard"
              style={{ textAlign: "left" }}
              select
              fullWidth
              label="Estado"
              color="primary"
              name="estado"
              value={data?.estado || ""}
              onChange={(e) => {
                setData({ ...data, estado: e.target.value });
              }}
            >
              <MenuItem value="" disabled selected>
                Seleccione un estado
              </MenuItem>
              {ESTADOS.map((estado, key) => (
                <MenuItem key={key} value={estado}>
                  {estado}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              variant="standard"
              label="País"
              fullWidth
              required
              value={data?.pais || ""}
              onChange={(e) => {
                setData({ ...data, pais: e.target.value });
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              variant="standard"
              label="Teléfono"
              fullWidth
              required
              value={data?.telefono || ""}
              onChange={(e) => {
                const regex = /^[0-9\b]+$/;
                if (e.target.value == "" || regex.test(e.target.value)) {
                  setData({ ...data, telefono: e.target.value });
                }
              }}
              inputProps={{ maxLength: 10 }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              variant="standard"
              label="Correo electrónico"
              fullWidth
              required
              value={data?.email || ""}
              onChange={(e) => {
                setData({ ...data, email: e.target.value });
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              variant="standard"
              label="Facebook"
              fullWidth
              value={data?.facebook || ""}
              onChange={(e) => {
                setData({ ...data, facebook: e.target.value });
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              variant="standard"
              label="Instagram"
              fullWidth
              value={data?.instagram || ""}
              onChange={(e) => {
                setData({ ...data, instagram: e.target.value });
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              variant="standard"
              label="Tik Tok"
              fullWidth
              value={data?.tiktok || ""}
              onChange={(e) => {
                setData({ ...data, tiktok: e.target.value });
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              variant="standard"
              label="X (Antes Twitter)"
              fullWidth
              value={data?.twitter || ""}
              onChange={(e) => {
                setData({ ...data, twitter: e.target.value });
              }}
            />
          </Grid>
          <Grid item xs={12} marginTop={5}>
            <Typography variant="subtitle1">
              Información sobre su cocina
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              variant="standard"
              label="Página web"
              fullWidth
              value={data?.web || ""}
              onChange={(e) => {
                setData({ ...data, web: e.target.value });
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              variant="standard"
              label="¿Tiene experiencia en la preparación de platillos con chocolate?"
              fullWidth
              value={data?.experiencia || ""}
              onChange={(e) => {
                setData({ ...data, experiencia: e.target.value });
              }}
              required
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              variant="standard"
              label="Descripción del platillo que presentará"
              fullWidth
              value={data?.platillo || ""}
              onChange={(e) => {
                setData({ ...data, platillo: e.target.value });
              }}
              required
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              variant="standard"
              label="Nombre de su emprendimiento gastronómico"
              fullWidth
              value={data?.emprendimiento || ""}
              onChange={(e) => {
                setData({ ...data, emprendimiento: e.target.value });
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              variant="standard"
              label="¿Te interesa impartir una charla?"
              fullWidth
              value={data?.charla || ""}
              onChange={(e) => {
                setData({ ...data, charla: e.target.value });
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              variant="standard"
              label="¿Quieres participar en el Concurso del Chocolate de Mesa en Agua?"
              fullWidth
              value={data?.participarChocolateMesaAgua || ""}
              onChange={(e) => {
                setData({
                  ...data,
                  participarChocolateMesaAgua: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              variant="standard"
              label="¿Impartirías un taller de cocina?"
              fullWidth
              value={data?.tallerCocina || ""}
              onChange={(e) => {
                setData({
                  ...data,
                  tallerCocina: e.target.value,
                });
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              variant="standard"
              label="¿Nos harías una degustación/cata de chocolate?"
              fullWidth
              value={data?.cataChocolate || ""}
              onChange={(e) => {
                setData({
                  ...data,
                  cataChocolate: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              variant="standard"
              style={{ textAlign: "left" }}
              select
              fullWidth
              label="Categoria"
              color="primary"
              name="categoria"
              value={data?.conversatorio || ""}
              onChange={(e) => {
                setData({ ...data, conversatorio: e.target.value });
              }}
            >
              <MenuItem value="" disabled selected>
                Seleccione una opción
              </MenuItem>
              {conversatorios.map((item, key) => (
                <MenuItem key={key} value={item}>
                  {item}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} marginTop={5}>
            <Typography variant="caption" whiteSpace="pre-wrap">
              1. Declaro que lo mencionado en este documento es verdad. {"\n"}
              2. Acepto los términos y condiciones del concurso.{"\n"}3. He
              leído y estoy de acuerdo con la política de privacidad.{"\n"}4.
              Una sola cocinera representa a su estado en el Duelo Internacional
              de Fogones.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="contained"
              fullWidth
              onClick={handleSubmit}
              disabled={loading ? true : false}
            >
              {loading ? "Guardando registro" : "Finalizar registro"}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Footer />
    </Container>
  );
};

export default DuelodFogonesInternacional;
